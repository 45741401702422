<template>
  <div class="container">
    <BasicTitle title="Nueva Visita" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Nombre *" />
      <BorderInput v-model="visit.data.name" label="Nombre *" />
      <FormError :show="visit.rules.name" message="Ingrese un nombre" />

      <BasicLabel label="CI *" />
      <BorderInput v-model="visit.data.identity" type="number" label="CI *" />
      <FormError :show="visit.rules.identity" message="Ingrese un CI válido" />

      <BasicLabel label="Apartamento *" />
      <BorderSelect
        v-model="visit.data.apartmentId"
        label="Apartamento *"
        :options="store.state.visit.visitApartments"
      />
      <FormError
        :show="visit.rules.apartmentId"
        message="Seleccione un apartamento"
      />

      <BasicLabel label="Fecha de Agenda *" />
      <BorderDate v-model="visit.data.date" label="Fecha de Agenda *" />
      <FormError :show="visit.rules.date" message="Seleccione una fecha" />

      <BasicLabel label="Hora de Agenda *" />
      <BorderTime v-model="visit.data.time" label="Hora de Agenda *" />
      <FormError :show="visit.rules.time" message="Seleccione una hora" />

      <BasicLabel label="Comentario" />
      <OutlinedTextArea v-model="visit.data.coment" />
      <FormError />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode } from "../../constants";
import { validation, actions, aws } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    BorderDate,
    BorderTime,
    FormError,
    PrimaryButton,
    BorderFile,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const visit = reactive({
      data: {
        id: -1,
        apartmentId: "",
        name: "",
        identity: "",
        date: "",
        time: "",
        comment: "",
      },
      rules: {
        apartmentId: false,
        name: false,
        identity: false,
        date: false,
        time: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!visit.data.apartmentId) {
        visit.rules.apartmentId = true;
        valid = false;
      }
      if (!visit.data.name) {
        visit.rules.name = true;
        valid = false;
      }
      if (!visit.data.identity || !validation.validateCI(visit.data.identity)) {
        visit.rules.identity = true;
        valid = false;
      }
      if (!visit.data.date) {
        visit.rules.date = true;
        valid = false;
      }
      if (!visit.data.time) {
        visit.rules.time = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const date = new Date(visit.data.date);
      const time = new Date(visit.data.time);
      date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

      const request = {
        name: visit.data.name,
        identity: visit.data.identity,
        apartmentId: visit.data.apartmentId,
        visitStatusId: 2,
        date: new Date(date).getTime(),
        comment: visit.data.comment,
        userId: store.state.auth.user.id,
        buildingId: store.state.general.selectedBuilding,
      };

      const response = await store.dispatch(
        actions.visitActions.create,
        request
      );

      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedVisits", []);
        router.push("/visitas/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/visitas/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      store.commit("setLoading", true);
      await store.dispatch(
        actions.visitActions.apartments,
        store.state.general.selectedBuilding
      );
      await store.dispatch(
        actions.visitActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (visit.data.name) visit.rules.name = false;
      if (visit.data.identity && validation.validateCI(visit.data.identity))
        visit.rules.identity = false;
      if (visit.data.date) visit.rules.date = false;
      if (visit.data.time) visit.rules.time = false;
      if (visit.data.apartmentId) visit.rules.apartmentId = false;
    });

    return { store, visit, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.link-label {
  margin-right: 1rem;
  font-family: "klavika";
  color: var(--primary);
}
</style>
